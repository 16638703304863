/* ---- reset ---- */
html,
body {
  background-color: #000;
  font-family: "Roboto", sans-serif;
  width: 100%;
  height: 100vh;
  margin: 0;
}
* {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
}

/* ---- particles.js container ---- */
#particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 20%;
  background-position: 50% 50%;
  z-index: 1;
}
.bm {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.block {
  position: absolute;
  top: 60%;
  height: 80px;
  width: 50%;
  margin: auto;
  color: white;
  bottom: 0;
  right: 0;
  left: 0;
}

/*------------portfolio------------*/
h2 {
  font-size: 100px;
  font-family: "Roboto", sans-serif;
  padding: 20px 20px;
  color: #333;
}

.button-group {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  margin: 30px auto;
  padding: 20px;
  margin-top: 10%;
}

.btn-filter {
  margin-right: 15px;
  padding: 10px 15px;
  font-size: 14px;
  min-width: 100px;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  color: #555;
  border: 1px solid #555;
  border-radius: 1.5em;
}
.btn-filter:hover {
  background-color: #eee;
}
.btn-filter.active {
  background-color: black;
  color: #dcf0ef;
  border: 1px solid white;
}

.wrap {
  margin: 0 15px;
}

.grid-sizer {
  width: 33.33333%;
}

.grid-item {
  float: right;
  width: 33.33333%;
  padding: 10px 5px 0 5px;
  background: black;
  border: 1px solid black;
  box-sizing: border-box;
}
.grid-item:hover .info-wrapper {
  transform: translateY(0vw);
  opacity: 1;
}
.grid-item img {
  width: 100%;
}

.info-wrapper {
  position: absolute;
  font-family: "Roboto", sans-serif;
  bottom: 0;
  padding: 78px 16px 16px 16px;
  box-sizing: border-box;
  background-image: linear-gradient(
    -179deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.49) 98%
  );
  width: calc(100% - 10px);
  height: auto;
  opacity: 0;
  transform: translateY(3rem);
  transition: 0.25s transform linear, 0.3s opacity ease-in-out;
}
.info-wrapper .name {
  color: white;
  font-size: 1.5vw;
  margin-bottom: 0.05rem;
}
.info-wrapper .memo {
  color: #ddd;
  font-size: 0.8vw;
  margin-bottom: 0.2rem;
}
.info-wrapper .date {
  color: white;
  font-size: 0.8vw;
}
.about {
  line-height: 200%;
}
/*------------social media icons------------*/
.socialbtns a {
  color: white;
  font-size: 1.8em;
}
.socialbtns a:hover {
  color: pink;
}
.footer {
  position: fixed;
  font-family: "Roboto", sans-serif;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  color: grey;
  text-align: center;
  z-index: 3;
}
/*------------Name Animation------------*/
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
}

.line {
  display: flex;
  position: absolute;
  z-index: 3;
}

.square {
  font-size: 20em;
  font-weight: 500;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  cursor: default;
  transition: all 0.3s;
  color: rgba(164, 75, 249, 0.1);
}

.square:hover {
  color: #ff00c3;
}
#custom-space {
  margin-left: 30px;
}

@-webkit-keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    -ms-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    -ms-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    -ms-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    -ms-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    -ms-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
}

@keyframes movement {
  from {
    -webkit-transform: rotate(-1turn) translateY(-50px);
    transform: rotate(-1turn) translateY(-50px);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}

/*---------------Download Animation------------*/

.btn-open {
  font-size: 1rem;
  position: absolute;
  z-index: 5;
  cursor: pointer;
  opacity: 0;
  user-select: none;
  visibility: hidden;
  pointer-events: none;
}
.content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.btn-download {
  font-size: 1rem;
  cursor: pointer;
  border: 1px solid white;
  background-color: #040404;
  color: #fff;
  display: block;
  width: 200px;
  height: 50px;
  transition: all 0.3s ease;
  transform-origin: center bottom;
  transform: scale(1);
  position: relative;
  overflow: hidden;
  outline: none;
}

.btn-download:hover {
  background-color: #a0a0a0;
}

.btn-download:before,
.btn-download:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.btn-download:after {
  background: white;
  border-radius: 5px;
  transform: translateX(-100%);
}

.btn-download:focus {
  font-size: 0;
  height: 10px;
  border-radius: 20px;
  background-color: black;
  animation-name: stretch;
  animation-delay: 1.5s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.btn-download:focus + .btn-open {
  animation-name: appear;
  animation-delay: 2s;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.btn-download:focus:after {
  transform: translateX(0);
  transition: transform cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition-delay: 1s;
}

@keyframes stretch {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.4);
  }
}

@keyframes appear {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(0);
  }
  to {
    opacity: 1;
    visibility: visible;
    transform: translateY(-15px);
  }
}
/*------------Loader------------*/

.loader-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-transition-enter {
  transform: translate(100%);
  position: absolute;
}

.page-transition-enter.page-transition-enter-active {
  transform: translate(0%);
  transition: transform 1s ease-in-out;
}

.page-transition-leave {
  transform: translate(0%);
  position: absolute;
}

.page-transition-leave.page-transition-leave-active {
  transform: translate(-100%);
  transition: transform 1s ease-in-out;
}
/*------------For tablet------------*/
@media only screen and (max-width: 1024px) {
  .square {
    font-size: 10em;
  }
}
/*------------For mobile phones------------*/
@media only screen and (max-width: 620px) {
  .wrapper,
  .block,
  .button-group {
    width: 100%;
  }
  .container {
    width: 100%;
  }
  .square {
    font-size: 5em;
  }
  #logo {
    display: none;
  }
  .button-group {
    padding: 0;
    margin-top: 15%;
  }
  .btn-filter {
    margin-right: 5px;
    top: 50px;
    margin-top: 5%;
    padding: 5px 10px;
    font-size: 1em;
    min-width: 40px;
  }
  .info-wrapper {
    padding: 78px 16px 16px 16px;
    height: 25vw;
  }
  #AboutTextContainer {
    width: 100%;
    left: 0;
    top: 60%;
  }
  #img_yiyi {
    width: 100%;
  }
  #icons {
    position: absolute;
    z-index: 999;
    text-align: center;
    width: 100%;
    top: 40%;
    left: 0;
  }
}

#tsparticles {
  width: 100%;
  z-index: 0;
}
